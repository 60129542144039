import React, { useEffect, useState } from "react"
import { getVariables } from "../lib/utils"
import { Link } from "gatsby"
import { useAllMattresses } from "../hooks/useAllMattresses"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import srcIconAngleDouble from "../images/icon-angle-double.svg"
import srcIconPlus from "../images/icon-plus.svg"

const CompareSidebarSearch = ({ className = '', rawSlugs }) => {
    let variables = getVariables()

    const { nodes }  = useAllMattresses()
    const [searchTerm, setSearchTerm] = useState("")
    const [searchResults, setSearchResults] = useState([])

    const showWarning = rawSlugs.length >= 3 ? true : false
    const linkRel = rawSlugs.length >= 2 ? 'nofollow' : ''

    let mattressesUrl = []
    for (let i = 0; i < rawSlugs.length; i++) {
        mattressesUrl.push(variables.mattress + '-' + rawSlugs[i])
    }

    const handleChange = (event) => {
        setSearchTerm(event.target.value)
    }

    useEffect(() => {
        const results = nodes.filter(item =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        setSearchResults(results)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])



    return (
        <aside className={`${className} flex flex-col mt-4 mb-8 lg:mb-4`}>
            <div className="sticky top-2.5">
                <div className="text-lg flex items-center mb-2 text-zone-1 font-signika">
                    <img src={srcIconAngleDouble} alt="⇣" width="13" height="20" className="mr-2" /> {variables.add_another_mattress}
                </div>
                <p className={`text-zone-4 rounded-lg py-3 ${showWarning ? 'block' : 'hidden'}`}>{variables.message_limit}</p>

                <div>
                    <input
                        type="text"
                        placeholder={variables.enter_mattress_name}
                        value={searchTerm}
                        onChange={handleChange}
                        className="w-full mb-4 p-2 text-base text-zone-3 border border-zone-2 bg-zone-3"
                    />
                    <p className="font-signika text-lg mb-4 text-zone-2">{variables.popular_comparisons}</p>

                    <ul className="overflow-y-auto sidebar-full-calc">
                        {searchResults.map((mattress, i) => {
                            const mattressFound = rawSlugs.indexOf(mattress.slug)
                            if (mattressFound === -1) {
                                const image = getImage(mattress.mattressImage)

                                return (
                                    <li key={`sidebar-item-key-${i}`} className="mb-3">
                                        <Link to={`/${variables.comparer}/${mattressesUrl.join('-vs-')}-vs-${variables.mattress}-${mattress.slug}`} className="flex justify-between items-center border border-transparent py-1 px-2 font-grotesk font-semibold duration-300 underline text-zone-2 hover:text-zone-1 hover:border-zone-3 hover:no-underline" rel={linkRel}>
                                            <GatsbyImage image={image} alt={mattress.name} className="mr-4" />
                                            <span className="flex-auto">{mattress.name}</span>
                                            <img src={srcIconPlus} alt="+" width="16" height="16" className="mr-2" />
                                        </Link>
                                    </li>
                                )
                            } else {
                                return null
                            }
                        })}
                    </ul>
                </div>

            </div>
        </aside>
    )
}

export default CompareSidebarSearch