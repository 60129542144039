import React from 'react'
import ReactPlayer from 'react-player/lazy'

const CompareVideo = ({mattress}) => {
    //this component can grow to support embed code or direct url, but for now lets use embed code
    let hasVideo = null
    if (mattress.externalVideo) {
        if (mattress.externalVideo.provider === 'vimeo') {
            hasVideo = <ReactPlayer url={mattress.externalVideo.url} light={true} height="auto" width="100%" className="aspect-video"  controls={true} />
        }
        else {
            hasVideo = <ReactPlayer url={mattress.externalVideo.url} light={true} height="auto" width="100%" className="aspect-video" />
        }
    } else {
        if (mattress.videoEmbedCode) {
            hasVideo = <div dangerouslySetInnerHTML={{ __html: mattress.videoEmbedCode }} />
        }
    }


    return (
        <>{hasVideo}</>
    )
}

export default CompareVideo
