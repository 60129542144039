import React, { useState, useEffect, useRef } from 'react'
import * as myStyles from './FadeInSection.module.css'

const FadeInSection = (props) => {
    const [isVisible, setVisible] = useState(true)
    const domRef = useRef()

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting))
        })
        const current = domRef.current
        observer.observe(current)

        return () => current ? observer.unobserve(current) : null
    }, [])

    return (
        <div className={`${myStyles.fadeInSection} ${isVisible ? myStyles.isVisible : ''}`} ref={domRef} >
            {props.children}
        </div>
    );
}


export default FadeInSection
