import React from "react"
import { getVariables } from "../lib/utils"

const PageTitle = ({ newestDate = null, title, subTitle = null, link = null, category = null, rel = null }) => {
    const variables = getVariables()
    let hasTitle = ''
    let hasDate = ''

    if (link) {
        hasTitle = <h1 className="text-zone-1 text-5xl font-playfair font-bold mb-6">
            <a href={link} rel={rel} className="underline hover:no-underline">
                {title}
            </a>
        </h1>
    } else {
        hasTitle = <h1 className="text-zone-1 text-5xl font-playfair font-bold mb-6">{title}</h1>
    }

    if (newestDate) {
        hasDate = <span className="text-sm text-zone-2">
            <time dateTime={`${newestDate.year}-${newestDate.month}-${newestDate.day}`}>{variables.last_updated_on} <span>{newestDate.month}</span> <span>{newestDate.day}</span>, <span>{newestDate.year}</span></time>
        </span>
    }


    return (
        <div className="text-center lg:text-left pt-4 px-2 md:px-4 xl:px-0">
            <p className="mb-4"><span className="text-sm mr-2 uppercase text-zone-5">{category ? category: ''}</span> {hasDate}</p>
            {hasTitle}
            <h2 className="text-2xl text-zone-6 font-grotesk font-semibold mb-4">{subTitle ? subTitle : ''}</h2>
        </div>
    )
}

export default PageTitle