import React, { useEffect } from "react"

import ComparePromotions from "./ComparePromotions"
import ComparePrices from "./ComparePrices"
import CompareSizes from "./CompareSizes"
import CompareVideo from "./CompareVideo"
//import CompareArticles from "./CompareArticles"
//import CompareCertifications from "./CompareCertifications"

import srcIconTimes from "../images/icon-times.svg"
import srcIconCheck from "../images/icon-check-square.svg"

const CompareBody = ({ mattresses, translations, location, sizes }) => {

    const dimensionsSymbol = location.dimensionsSymbol
    const weightSymbol = location.weightSymbol
    const currencySymbol = location.currencySymbol
    const totalColumns = mattresses.length

    useEffect(() => {

        function handleResize() {
            let priceHeights = []
            let thePrices = document.getElementsByClassName("affiliate-link")
            for (let i = 0; i < thePrices.length; i++) {
                //reset the sizes to the default
                thePrices[i].style.height = "auto"
                priceHeights.push(thePrices[i].clientHeight)
            }

            //now we get the max value
            let max = Math.max.apply(null, priceHeights)

            //let"s try to update the height with the max value
            for (let i = 0; i < thePrices.length; i++) {
                thePrices[i].style.height = max + "px"
            }
        }

        handleResize()

        window.addEventListener("resize", handleResize)
        return _ => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const iconTimes = <img src={srcIconTimes} alt="✕" width="30" height="30" />
    const iconCheck = <img src={srcIconCheck} alt="✓" width="26" height="30" />

    return (
        <table className="table-compare">
            <tbody>
                <ComparePromotions mattresses={mattresses} label={translations.latestPromotions} />

                <tr className="primary-section">
                    <th colSpan={totalColumns}>
                        <h3>{translations.essentialMattressInfo}</h3>
                    </th>
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.brand}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`brand-key-${i}`}>{mattress.brand.brandName}</td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.mattressType}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`mattress-type-key-${i}`}>{mattress.mattressType.typeName}</td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.prices}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`prices-top-key-${i}`}>
                            <ComparePrices
                                buttons={mattress.buttons}
                                mattress={mattress}
                                currencySymbol={currencySymbol}
                                allSizes={sizes}
                                position="top" />
                        </td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.otherSizesAvailable}</td>
                </tr>
                <tr className="center-section">
                    {mattresses.map((mattress, i) => {
                        return (<td key={`other-sizes-available-key-${i}`}>{mattress.otherSizesAvailable ? iconCheck : iconTimes}</td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.video}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`video-key-${i}`}><CompareVideo mattress={mattress} /></td>)
                    })}
                </tr>

                <tr className="primary-section">
                    <th colSpan={totalColumns}>
                        <h3>{translations.trialWarranty}</h3>
                    </th>
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.trialPeriod}</td>
                </tr>
                <tr className="center-section">
                    {mattresses.map((mattress, i) => {
                        return (<td key={`trial-period-key-${i}`}>
                            {mattress.trialPeriod ? iconCheck : iconTimes}
                        </td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.trialPeriodNights}</td>
                </tr>
                <tr className="center-section">
                    {mattresses.map((mattress, i) => {
                        return (<td key={`trial-nights-key-${i}`}>{mattress.trialPeriodNights === 999 ? "FOREVER" : mattress.trialPeriodNights}</td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.warrantyLengthYears}</td>
                </tr>
                <tr className="center-section">
                    {mattresses.map((mattress, i) => {
                        let warrantyLengthYears = ''
                        switch (mattress.warrantyLengthYears) {
                            case 999:
                                warrantyLengthYears = 'LIFETIME'
                                break
                            case 1000:
                                warrantyLengthYears = 'FOREVER'
                                break
                            case 1001:
                                warrantyLengthYears = 'EVERLASTING'
                                break
                            case 1002:
                                warrantyLengthYears = 'INFINITE'
                                break
                            default:
                                warrantyLengthYears = mattress.warrantyLengthYears
                                break
                        }
                        return (<td key={`warranty-length-key-${i}`}>{warrantyLengthYears}</td>)
                    })}
                </tr>

                <tr className="primary-section">
                    <th colSpan={totalColumns}>
                        <h3>{translations.firmnessOptions}</h3>
                    </th>
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.oneFirmnessFitsAll}</td>
                </tr>
                <tr className="center-section">
                    {mattresses.map((mattress, i) => {
                        return (<td key={`brand-key-${i}`}>{mattress.oneFirmnessFitsAll ? iconCheck : iconTimes}</td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.adjustableFirmness}</td>
                </tr>
                <tr className="center-section">
                    {mattresses.map((mattress, i) => {
                        return (<td key={`adjustable-firmness-key-${i}`}>{mattress.ajustableFirmness ? iconCheck : iconTimes}</td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.halfHalfFirmness}</td>
                </tr>
                <tr className="center-section">
                    {mattresses.map((mattress, i) => {
                        return (<td key={`half-half-firmness-key-${i}`}>{mattress.halfHalfFirmness ? iconCheck : iconTimes}</td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.selectableFirmness}</td>
                </tr>
                <tr className="center-section">
                    {mattresses.map((mattress, i) => {
                        return (<td key={`selectable-firmness-key-${i}`}>{mattress.selectableFirmness ? iconCheck : iconTimes}</td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.firmnessInfo}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`firmness-info-key-${i}`}><div dangerouslySetInnerHTML={{ __html: mattress.firmnessInformationNode.childMarkdownRemark.html }} /></td>)
                    })}
                </tr>

                <tr className="primary-section">
                    <th colSpan={totalColumns}>
                        <h3>{translations.mattressConstruction}</h3>
                    </th>
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.materialsOverview}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`materials-overview-key-${i}`}><div dangerouslySetInnerHTML={{ __html: mattress.mattressOverviewNode.childMarkdownRemark.html }} /></td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.comfortInfo}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`comfort-info-key-${i}`}><div dangerouslySetInnerHTML={{ __html: mattress.comfortInfoNode.childMarkdownRemark.html }} /></td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.supportInfo}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`support-info-key-${i}`}><div dangerouslySetInnerHTML={{ __html: mattress.supportInfoNode.childMarkdownRemark.html }} /></td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.comfortSpecs}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`comfort-specs-key-${i}`}><div dangerouslySetInnerHTML={{ __html: mattress.comfortSpecsNode.childMarkdownRemark.html }} /></td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.supportSpecs}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`support-specs-key-${i}`}><div dangerouslySetInnerHTML={{ __html: mattress.supportSpecsNode.childMarkdownRemark.html }} /></td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.fabricSpecs}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`fabric-specs-key-${i}`}><div dangerouslySetInnerHTML={{ __html: mattress.fabricSpecsNode.childMarkdownRemark.html }} /></td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.manufacturedIn}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`manufactured-in-key-${i}`}>{mattress.manufacturedIn}</td>)
                    })}
                </tr>

                <tr className="primary-section">
                    <th colSpan={totalColumns}>
                        <h3>{translations.suitability}</h3>
                    </th>
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.sideSleepers}</td>
                </tr>
                <tr className="center-section">
                    {mattresses.map((mattress, i) => {
                        return (<td key={`side-sleepers-key-${i}`}>{mattress.suitableSideSleepers ? iconCheck : iconTimes}</td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.backSleepers}</td>
                </tr>
                <tr className="center-section">
                    {mattresses.map((mattress, i) => {
                        return (<td key={`back-sleepers-key-${i}`}>{mattress.suitableBackSleepers ? iconCheck : iconTimes}</td>)
                    })}
                </tr>


                <tr className="primary-section">
                    <th colSpan={totalColumns}>
                        <h3>{translations.sizeWeight}</h3>
                    </th>
                </tr>

                <CompareSizes
                    mattresses={mattresses}
                    dimensionsSymbol={dimensionsSymbol}
                    weightSymbol={weightSymbol}
                    labelDimension={translations.dimensions}
                    labelWeight={translations.weight}
                    labelSize={translations.mattresses} />

                <tr className="primary-section">
                    <th colSpan={totalColumns}>
                        <h3>{translations.delivery}</h3>
                    </th>
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.deliveryCost}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`delivery-cost-key-${i}`}>{mattress.deliveryCost}</td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.deliveryInfo}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`delivery-info-key-${i}`}><div dangerouslySetInnerHTML={{ __html: mattress.deliveryInformationNode.childMarkdownRemark.html }} /></td>)
                    })}
                </tr>

                <tr className="secondary-section">
                    <td colSpan={totalColumns}>{translations.prices}</td>
                </tr>
                <tr>
                    {mattresses.map((mattress, i) => {
                        return (<td key={`prices-bottom-key-${i}`}>
                            <ComparePrices
                                buttons={mattress.buttons}
                                mattress={mattress}
                                currencySymbol={currencySymbol}
                                allSizes={sizes}
                                position="bottom" />
                        </td>)
                    })}
                </tr>

                <ComparePromotions mattresses={mattresses} label={translations.latestPromotions} />
            </tbody>
        </table>
    )
}

export default CompareBody
