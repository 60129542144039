import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import srcIconTimes from "../images/icon-times.svg"
import srcIconCheck from "../images/icon-check-square.svg"

const CompareSizes = ({ mattresses, dimensionsSymbol, weightSymbol, labelDimension, labelWeight, labelSize }) => {

    const sizes = useStaticQuery(graphql`
    query {
        allDatoCmsMattressSize(filter: {locale: {eq: "es"}}, sort: {fields: position, order: ASC}) {
            nodes {
                sizeName
                id
            }
        }
    }
  `)

    const iconTimes = <img src={srcIconTimes} alt="✕" width="30" height="30" className="mx-auto" />
    const iconCheck = <img src={srcIconCheck} alt="✓" width="26" height="30" className="mx-auto" />

    const allSizes = sizes.allDatoCmsMattressSize.nodes
    let sizeRow = []
    for (let i = 0; i < allSizes.length; i++) {
        let sizeColumn = []
        for (let j = 0; j < mattresses.length; j++) {
            let buttons = mattresses[j].buttons
            let sizeFound = false
            for (let k = 0; k < buttons.length; k++) {
                if (allSizes[i].id === buttons[k].size.id) {
                    sizeColumn.push(buttons[k])
                    sizeFound = true
                }
            }
            //lets ask if we found the size
            if (sizeFound === false) {
                //if not then lets 
                sizeColumn.push(null)
            }
        }

        //we have so many sizes so lets make sure we are not adding columns that are just null values
        let allNull = sizeColumn.every(function (v) { return v === null; })

        if (sizeColumn.length > 0) {
            if (allNull === false) {
                //at least one element of the array is not null then add
                sizeRow.push(sizeColumn)
            }
        }
    }

    const stylesRow = [
        'bg-zone-13',
        'bg-zone-3',
        'bg-zone-13',
        'bg-zone-3',
        'bg-zone-13',
        'bg-zone-3',
        'bg-zone-13',
        'bg-zone-3',
        'bg-zone-13',
        'bg-zone-3',
    ]

    //now lets iterate each row
    let htmlSize = []
    for (let i = 0; i < sizeRow.length; i++) {
        let row = sizeRow[i]

        let auxSize = []
        let auxDimension = []
        let auxWeight = []

        let sizeLabel = labelSize
        for (let j = 0; j < row.length; j++) {
            //at this point we are looking all the attributes searching for the 3 specific ones
            let column = row[j]
            if (column === null) {
                auxSize.push(<td key={`size-item-column-a-${j}-${i}`}>{iconTimes}</td>)
                auxDimension.push(<td key={`size-item-column-b-${j}-${i}`}>{iconTimes}</td>)
                auxWeight.push(<td key={`size-item-column-c-${j}-${i}`}>{iconTimes}</td>)
            } else {
                sizeLabel = column.size.sizeName
                auxSize.push(<td key={`size-item-column-a-${j}-${i}`}>{iconCheck}</td>)
                auxDimension.push(<td key={`size-item-column-b-${j}-${i}`}>{column.dimensionLength} x {column.dimensionWidth} x {column.dimensionHeight} {dimensionsSymbol}</td>)
                auxWeight.push(<td key={`size-item-column-c-${j}-${i}`}>{column.weight}</td>)
            }
        }

        htmlSize.push(<tr className={stylesRow[i]} key={`size-item-row-a-label-${i}`}><td className="font-bold underline" colSpan={row.length}>{sizeLabel}</td></tr>)
        htmlSize.push(<tr className={`${stylesRow[i]} text-center`} key={`size-item-row-a-${i}`}>{auxSize}</tr>)
        
        htmlSize.push(<tr className={stylesRow[i]} key={`size-item-row-b-label-${i}`}><td colSpan={row.length}>{sizeLabel} {labelDimension}</td></tr>)
        htmlSize.push(<tr className={`${stylesRow[i]} text-center`} key={`size-item-row-b-${i}`}>{auxDimension}</tr>)
        
        htmlSize.push(<tr className={stylesRow[i]} key={`size-item-row-c-label-${i}`}><td colSpan={row.length}>{sizeLabel} {labelWeight} ({weightSymbol})</td></tr>)
        htmlSize.push(<tr className={`${stylesRow[i]} text-center`} key={`size-item-row-c-${i}`}>{auxWeight}</tr>)
        
    }

    return (
        <>
            {htmlSize}
        </>
    )
}

export default CompareSizes