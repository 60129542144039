import { useStaticQuery, graphql } from "gatsby"

export const useAllMattresses = () => {
    const data = useStaticQuery(graphql`
        query {
            allDatoCmsMattress(filter: {locale: {eq: "es"}, location: {websiteUrl: {eq: "co.mattress.zone"}}}) {
                nodes {
                    slug
                    name
                    location {
                        websiteUrl
                    }
                    mattressImage {
                        gatsbyImageData(
                            imgixParams: {auto: "format", fit: "crop", h: "35", w: "70", colors: 10, mask: "corners", cornerRadius: "10,10,10,10"}
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
    `)

    return data.allDatoCmsMattress
}