import React from 'react'

import FadeInSection from './FadeInSection'

const ComparePrices = ({ buttons, mattress, currencySymbol, allSizes, position }) => {
    let hasPrices = []
    const currencyStyle = 'es-CO'
    const currency = 'COP'

    let singleMattress = mattress
    //lets get all the possible discounts
    let discountFlat = null   //in this case it's equal to 0
    let discountPercentage = null //in this case it's equal to 0

    if (singleMattress.discountFlat !== null && singleMattress.discountFlat !== undefined) {
        discountFlat = singleMattress.discountFlat
    } else {
        if (singleMattress.brand.discountFlat !== null && singleMattress.brand.discountFlat !== undefined) {
            discountFlat = singleMattress.brand.discountFlat
        }
    }

    if (singleMattress.discountPercentage !== null && singleMattress.discountPercentage !== undefined) {
        discountPercentage = singleMattress.discountPercentage
    } else {
        if (singleMattress.brand.discountPercentage !== null && singleMattress.brand.discountPercentage !== undefined) {
            discountPercentage = singleMattress.brand.discountPercentage
        }
    }

    for (let i = 0; i < allSizes.length; i++) {
        let addEmpty = true
        if (buttons && buttons.length) {
            buttons.forEach(function (button, index, array) {
                if (allSizes[i] === button.size.sizeName) {
                    let theLink = null
                    if (button.affiliateLinkButton !== null) {
                        if (button.affiliateLinkButton.shortenedAffiliateLink !== null) {
                            theLink = button.affiliateLinkButton.shortenedAffiliateLink
                        } else {
                            if (button.affiliateLinkButton.affiliateLink !== null) {
                                theLink = button.affiliateLinkButton.affiliateLink
                            }
                        }
                    }

                    if (theLink !== null) {
                        let price = new Intl.NumberFormat(currencyStyle, { style: 'currency', currency: currency }).format(button.price)
                        let result = null

                        if (button.salePrice !== null && button.salePrice !== undefined) {
                            result = new Intl.NumberFormat(currencyStyle, { style: 'currency', currency: currency }).format(button.salePrice)
                            hasPrices.push(
                                <FadeInSection key={index}>
                                    <a
                                        id={`gaclickaff-${button.id}-${position}`}
                                        target="_blank"
                                        rel="sponsored noreferrer"
                                        key={`gaclickaff-${button.id}`}
                                        className={`affiliate-link link-mattress-size-${button.size.sizeName} block bg-zone-12 text-zone-1 rounded-full text-lg leading-5 font-signika p-4 mb-6 md:mb-3 text-center duration-300 hover:bg-zone-14 hover:text-white`}
                                        href={button.affiliateLinkButton.shortenedAffiliateLink}>
                                        {button.size.sizeName}<br />
                                        <s className="mr-2">{price}</s>
                                        <b>{result}</b><br />
                                        <b>[{button.affiliateLinkCta}]</b>
                                    </a>
                                </FadeInSection>)
                        } else {
                            if (discountFlat && discountPercentage) {
                                //calculate both and choose the least expensive one
                                let calcDiscountFlat = button.price - discountFlat
                                let calcDiscountPercentage = (button.price * (100 - discountPercentage)) / 100

                                let result = 0
                                if (calcDiscountFlat > calcDiscountPercentage) {
                                    result = new Intl.NumberFormat(currencyStyle, { style: 'currency', currency: currency }).format(calcDiscountPercentage)
                                } else {
                                    result = new Intl.NumberFormat(currencyStyle, { style: 'currency', currency: currency }).format(calcDiscountFlat)
                                }
                                hasPrices.push(
                                    <FadeInSection key={index}>
                                        <a id={`gaclickaff-${button.id}-${position}`}
                                            target="_blank"
                                            rel="sponsored noreferrer"
                                            key={`gaclickaff-${button.id}`}
                                            className={`affiliate-link link-mattress-size-${button.size.sizeName} block bg-zone-12 text-zone-1 rounded-full text-lg leading-5 font-signika p-4 mb-6 md:mb-3 text-center duration-300 hover:bg-zone-14 hover:text-white`}
                                            href={button.affiliateLinkButton.shortenedAffiliateLink}>
                                            {button.size.sizeName}<br />
                                            <s className="mr-2">{price}</s>
                                            <b>{result}</b><br />
                                            <b>[{button.affiliateLinkCta}]</b>
                                        </a>
                                    </FadeInSection>)

                            } else {

                                if (discountFlat) {
                                    result = new Intl.NumberFormat(currencyStyle, { style: 'currency', currency: currency }).format(button.price - discountFlat)
                                } else {
                                    if (discountPercentage) {
                                        result = new Intl.NumberFormat(currencyStyle, { style: 'currency', currency: currency }).format((button.price * (100 - discountPercentage)) / 100)
                                    } else {
                                        result = null
                                    }
                                }

                                if (result) {
                                    hasPrices.push(
                                        <FadeInSection key={index}>
                                            <a id={`gaclickaff-${button.id}-${position}`}
                                                target="_blank"
                                                rel="sponsored noreferrer"
                                                key={`gaclickaff-${button.id}`}
                                                className={`affiliate-link link-mattress-size-${button.size.sizeName} block bg-zone-12 text-zone-1 rounded-full text-lg leading-5 font-signika p-4 mb-6 md:mb-3 text-center duration-300 hover:bg-zone-14 hover:text-white`}
                                                href={button.affiliateLinkButton.shortenedAffiliateLink}>
                                                {button.size.sizeName}<br />
                                                <s className="mr-2">{price}</s>
                                                <b>{result}</b><br />
                                                <b>[{button.affiliateLinkCta}]</b>
                                            </a>
                                        </FadeInSection>)
                                } else {
                                    hasPrices.push(
                                        <FadeInSection key={index}>
                                            <a id={`gaclickaff-${button.id}-${position}`}
                                                target="_blank"
                                                rel="sponsored noreferrer"
                                                key={`gaclickaff-${button.id}`}
                                                className={`affiliate-link link-mattress-size-${button.size.sizeName} block bg-zone-12 text-zone-1 rounded-full text-lg leading-5 font-signika p-4 mb-6 md:mb-3 text-center duration-300 hover:bg-zone-14 hover:text-white`}
                                                href={button.affiliateLinkButton.shortenedAffiliateLink}>
                                                {button.size.sizeName}<br />{price}<br />
                                                <b>[{button.affiliateLinkCta}]</b>
                                            </a>
                                        </FadeInSection>)
                                }
                            }
                        }
                    }

                    addEmpty = false
                }
            })
        }
        if (addEmpty) {
            hasPrices.push(<p className="affiliate-link hidden text-xl leading-5 p-4 mb-6 min-h-89 md:block md:mb-3" key={`price-empty-${i}`}></p>)
        }
    }

    return (
        <div>
            {hasPrices}
        </div>
    )
}

export default ComparePrices
