import React from 'react'
import { getPromotions } from '../lib/utils'

const ComparePromotions = ({ mattresses, label }) => {

    let result = null
    let totalPromotions = 0
    let promotions = []
    if (mattresses && mattresses.length) {
        mattresses.forEach((mattress, index) => {
            const brandName = mattress.brand.brandName

            //Iterating the mattress lets check for the brand promotion first
            if (mattress.brand.promotions && mattress.brand.promotions.length) {
                totalPromotions++
                promotions.push(<td key={`promotions-key-td-brand-${index}`}>{getPromotions(mattress.brand.promotions, brandName)}</td>)
            } else {
                //then the mattress promotion
                if (mattress.promotions && mattress.promotions.length) {
                    totalPromotions++
                    promotions.push(<td key={`promotions-key-td-mattress-${index}`}>{getPromotions(mattress.promotions, brandName)}</td>)
                } else {
                    promotions.push(<td key={`promotions-key-td-mattress-empty-${index}`}></td>)
                }
            }
        })
    }

    if (totalPromotions > 0) {
        result = <>
            <tr className="promotions border-t-2 border-zone-3 border-dashed" key={`promotions-key-tr-head-${totalPromotions}`}>
                <td className="text-zone-5 font-bold text-xl uppercase md:normal-case font-signika" key={`promotions-key-label-${totalPromotions}`} colSpan={mattresses.length}>{label}</td>
            </tr>
            <tr className="border-t-2 border-zone-3 border-dashed" key={`promotions-key-tr-content-${totalPromotions}`}>
                {promotions}
            </tr>
        </>
    }

    return (
        <>
            {result}
        </>
    )
}

export default ComparePromotions
